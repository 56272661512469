<template>
  <div>
    <div class="_pc-pad-y-60  _pc-pad-x-260  _mb-pad-y-60 _mb-pad-x-30  wow fadeInUp" >
      <div class="_pc-pad-x-25 _mb-pad-x-25 _mb-pad-y-15  _pc-pad-y-15 _flex-row-mid _border-04" style="background-color:#F5F5F5;">
        <div class="_flex-row-mid _flex-1">
          <img class="_pc-w-20  _pc-mar-right-20 _mb-w-25 _mb-mar-right-20" src="@/assets/image/search-icon.png" alt="">
          <input v-model="express_sn" placeholder="请输入物流单号" class="_flex-1 _pc-font-18 _pc-pad-y-5  _mb-font-28 _mb-pad-y-5" type="text" style="background-color:#F5F5F5;">
        </div>

        <div @click="search" style="cursor:pointer;" class="_pc-w-145 _mb-w-120 _mb-pad-y-15 _mb-font-26 _mb-mar-left-15 _pc-pad-y-10 _white _text-center _pc-font-18 _pc-mar-left-30 _border-04 theme-color-bg">查询</div>
      </div>


      <div class="_pc-mar-top-25 _w-max _pc-pad-x-15 _mb-mar-top-45 _mb-pad-x-15">
        <div class="_flex-row _w-max _pc-font-18 _mb-font-26" v-for="(item,index) in list" :key="index">
          <div class="_pc-w-65 _mb-w-55 _flex-row-center-mid _pos-rel _pc-pad-y-25 _mb-pad-y-25">
            <img v-if="index==0" class="_pc-w-25 _mb-w-35  _pos-rel _z-index-1" src="@/assets/image/now-route.png" alt="">
            <img v-else class="_pc-w-20 _mb-w-25  _pos-rel _z-index-1" src="@/assets/image/before-route.png" alt="">
            <div v-if="index!=0" class="_pos-abs _pc-w-2" style="height:50%;background-color:#AFAFAF;top:0;"></div>
            <div v-if="index+1 != list.length" class="_pos-abs _pc-w-2" style="height:50%;background-color:#AFAFAF;bottom:0;"></div>
          </div>

          <div class="_pc-mar-right-30 _flex-row-mid _pc-pad-y-25 _mb-display-none">{{item.track_date}}</div>
          <div class="_flex-row-mid _flex-1 _pc-pad-y-25 _mb-display-none">{{item.detail}}</div>

          <div class="_pc-display-none _mb-pad-y-25 _mb-flex-1 _mb-font-25">
            <div class="_mb-mar-bottom-10">{{item.track_date}}</div>
            <div style="line-height:1.7">{{item.detail}}</div>
          </div>
        </div>
      </div>


      <div class="_pc-mar-top-25 _flex-col-mid _w-max _mb-mar-top-45" v-if="list.length == 0">
        <img class="_pc-w-250 _pc-mar-bottom-15 _mb-mar-bottom-25 _mb-w-280" src="@/assets/image/none-data.png" alt="">
        <div class="_pc-font-12 _mb-font-18" style="color:#597598;">暂无物流进度信息</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'queryRoute',

  data() {
    return {
      list:[],
      express_sn:'',
    };
  },

  mounted() {
    
  },

  methods: {
    
    search(){
      let param = {
        express_sn:this.express_sn
      };
      let that = this;
      that.$axios.post('aboard_search',param).then(res=>{
        that.list = res.data.data;
        if(res.data.data.length == 0){
          alert('未查询出记录');
        }
      })
    }
  },
};
</script>

<style scoped>


._border-04{
  border-radius:0.4vw;
}

.theme-color{
  color: #4284DC;
}

._pc-w-2{
  width: 0.10418vw;
}
@media screen and (max-device-width: 768px) {
  ._pc-w-2{
    width: 0.26667vw;
  }
  ._border-04{
    border-radius:0.4vw;
  }
}
</style>